
	import { Component, Vue } from 'vue-property-decorator';
	import BodyText from '@pixcap/ui-library/components/Typography/BodyText.vue';
	import { mapGetters } from 'vuex';
	import { Getters as AppGetters, GetterTypes as AppGetterTypes, NAMESPACE as APP_NAMESPACE } from '@pixcap/ui-core/models/store/app.interface';

	@Component({
		name: 'CodePromotionBanner',
		components: {
			BodyText,
		},
		computed: {
			...mapGetters(APP_NAMESPACE, {
				codePromotion: AppGetterTypes.CODE_PROMOTION,
			}),
		},
	})
	export default class CodePromotionBanner extends Vue {
		codePromotion: ReturnType<AppGetters[AppGetterTypes.CODE_PROMOTION]>;

		isLoadingState = true;

		get isSyncClientUserState() {
			return this.isLoadingState;
		}

		get buttonText() {
			return this.$t('promo_banner.cta_text', { discount: this.codePromotion.discount });
		}

		get title() {
			return this.$t('promo_banner.title', { discount: this.codePromotion.discount, promo_code: this.codePromotion.promotionCode });
		}

		handleClickPromo() {
			this.$router.push({ name: 'PricingPage' });
		}

		mounted() {
			setTimeout(() => {
				this.isLoadingState = false;
			}, 1000);
		}
	}
