
	import { Component, Vue } from 'vue-property-decorator';
	import BodyText from '@pixcap/ui-library/components/Typography/BodyText.vue';
	import IconTag from '@pixcap/ui-library/components/Icons/IconTag.vue';
	import CountdownInput from '@pixcap/ui-library/components/Inputs/CountdownInput.vue';
	import { mapGetters, mapState } from 'vuex';
	import {
		Getters as AppGetters,
		GetterTypes as AppGetterTypes,
		NAMESPACE as APP_NAMESPACE,
		SpecialPromotionType,
	} from '@pixcap/ui-core/models/store/app.interface';
	import IconClose from '@pixcap/ui-library/components/Icons/IconClose.vue';
	import { mutationsWrapper as OrganismMutations } from '@/store/organism/wrapper';
	import { MY_ACCOUNT_TAB, NAMESPACE as USER_NAMESPACE, UserState } from '@pixcap/ui-core/models/store/user.interface';
	import { mutationsWrapper as UserMutations } from '@pixcap/ui-core/store/user';
	import IconArrowRight from '@pixcap/ui-library/components/Icons/IconArrowRight.vue';
	import IconLargeArrowRight from '@pixcap/ui-library/components/Icons/IconLargeArrowRight.vue';
	import { Getters as AuthGetters, GetterTypes as AuthGetterTypes, NAMESPACE as AUTH_NAMESPACE } from '@pixcap/ui-core/models/store/auth.interface';
	import { appUtilities } from '@/modules/appUtilities';
	import { SHOULD_SHOW_PROMOTION_BANNER } from '@pixcap/ui-core/constants/auth.constants';

	@Component({
		name: 'PromotionHeader',
		components: {
			IconLargeArrowRight,
			IconArrowRight,
			IconClose,
			CountdownInput,
			BodyText,
			IconTag,
		},
		computed: {
			...mapState(USER_NAMESPACE, {
				referralsInfo: (state: UserState) => state.referralsInfo,
			}),
			...mapGetters(APP_NAMESPACE, {
				currentPromotion: AppGetterTypes.CURRENT_PROMOTION,
			}),
			...mapGetters(AUTH_NAMESPACE, {
				isAuthenticated: AuthGetterTypes.IS_AUTHENTICATED,
			}),
		},
	})
	export default class PromotionHeader extends Vue {
		referralsInfo: UserState['referralsInfo'];
		isAuthenticated: ReturnType<AuthGetters[AuthGetterTypes.IS_AUTHENTICATED]>;
		currentPromotion: ReturnType<AppGetters[AppGetterTypes.CURRENT_PROMOTION]>;

		SpecialPromotionType = SpecialPromotionType;

		get isDiscountPromo() {
			return this.currentPromotion?.type === SpecialPromotionType.DISCOUNT_PROMO;
		}

		get expiredDate() {
			if (this.currentPromotion) {
				return this.currentPromotion.expiredDate;
			}

			return null;
		}

		get shouldShowFreebiesBanner() {
			if (!this.isDiscountPromo) {
				return appUtilities.$services.localStorageManager.read(SHOULD_SHOW_PROMOTION_BANNER) !== 0;
			} else {
				return true;
			}
		}

		get buttonText() {
			if (this.currentPromotion) {
				if (this.isDiscountPromo) {
					return `GET ${this.currentPromotion.discount}% OFF`;
				}
				return `Invite now`;
			}

			return 'Promotion Available';
		}

		get title() {
			if (this.currentPromotion) {
				return this.currentPromotion.promoBannerTitle;
			}

			return 'Promotion Available';
		}

		handleClickPromo() {
			if (this.currentPromotion) {
				this.$pixcap.$mixpanel.track('Click Promotion Banner', { promoName: this.currentPromotion.promotionName });
				if (this.isDiscountPromo) {
					this.$router.push({ name: 'PricingPage' });
				} else {
					if (!this.isAuthenticated) {
						UserMutations.showAuthenticationModal(this.$store, true);
						return;
					}
					this.$router.push({ name: 'UserSettingsPage' });
					UserMutations.setActiveMyAccountTab(this.$store, MY_ACCOUNT_TAB.INVITE_FRIENDS);
				}
			}
		}

		handleClose() {
			OrganismMutations.setShowPromotionHeader(this.$store, null);
			appUtilities.$services.localStorageManager.write(SHOULD_SHOW_PROMOTION_BANNER, 0);
		}
	}
